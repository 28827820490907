import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Toolbar,
  Hidden,
  List,
  ListItem,
  Typography,
  IconButton,
  FormControlLabel,
  Switch,
  Box,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { Image } from 'components/atoms';
import { isLightMode, sessionStorage } from 'utils';
import { Link } from 'gatsby';

const useStyles = makeStyles(theme => ({
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolbar: {
    zIndex: 999,
    position: 'fixed',
    backdropFilter: 'blur(20px)',
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  navLink: {
    fontWeight: 300,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  listItem: {
    cursor: 'pointer',
    '&:hover > .menu-item, &:hover svg': {
      color: theme.palette.primary.dark,
    },
    '&.menu-item--no-dropdown': {
      paddingRight: 0,
    },
  },
  listItemActive: {
    '&> .menu-item': {
      color: theme.palette.primary.dark,
    },
  },
  listItemText: {
    flex: '0 0 auto',
    marginRight: theme.spacing(0),
    whiteSpace: 'nowrap',
    fontWeight: '700',
  },
  listItemButton: {
    whiteSpace: 'nowrap',
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  popover: {
    padding: theme.spacing(4),
    border: theme.spacing(2),
    boxShadow: '0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)',
    minWidth: 350,
    marginTop: theme.spacing(2),
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    color: theme.palette.primary.dark,
  },
  logoContainer: {
    width: 200,
    height: 50,
    [theme.breakpoints.up('md')]: {
      width: 350,
      height: 50,
    },
  },
  logoImage: {
    width: '20%',
    height: '100%',
    [theme.breakpoints.up('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItem: {
    marginRight: theme.spacing(3),
    '&:last-child': {
      marginRight: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
  },
  darkmode: {
    marginLeft: 5,
  },
  logoTitle: {
    // border: '1px solid red',
    [theme.breakpoints.up('xs')]: {
      fontWeight: '700',
      fontSize: '1.5rem',
      lineHeight: '1.5rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontWeight: '700',
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontWeight: '700',
      fontSize: '2rem',
      lineHeight: '2rem',
    },
    fontFamily: "'Roboto', sans-serif",
  },
  logoSubTitle: {
    // border: '1px solid green',
    fontFamily: "'Roboto', sans-serif",
    [theme.breakpoints.up('xs')]: {
      fontWeight: '400',
      fontSize: '0.75rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontWeight: '400',
      fontSize: '0.75rem',
    },
    [theme.breakpoints.up('md')]: {
      fontWeight: '400',
      fontSize: '0.95rem',
      lineHeight: '0.75rem',
    },
  },
}));

const Topbar = props => {
  const { className, onSidebarOpen, nav, ...rest } = props;

  const classes = useStyles();

  const [isLightModeTheme, setLightModeTheme] = useState(false);

  useEffect(() => {
    setLightModeTheme(isLightMode());
  }, []);

  const toggleMode = () => {
    const currentMode = sessionStorage.getItem('themeMode') || '';
    if (currentMode === 'dark') {
      sessionStorage.setItem('themeMode', 'light');
    } else {
      sessionStorage.setItem('themeMode', 'dark');
    }

    window.location.reload();
  };

  return (
    <Toolbar disableGutters className={classes.toolbar} {...rest}>
      <Link to="/" title="thefront">
        <Box
          alignItems="center"
          display="flex"
          flexWrap="nowrap"
          className={classes.logoContainer}
        >
          {isLightModeTheme && (
            <Image
              className={classes.logoImage}
              src={require('assets/images/logo.svg')}
              alt="LOGO"
              lazy={false}
            />
          )}
          <Box>
            <Typography
              noWrap
              className={classes.logoTitle}
              color={isLightModeTheme ? 'primary' : 'textSecondary'}
            >
              HELEN GE CPA
            </Typography>
            <Typography
              noWrap
              className={classes.logoSubTitle}
              color={isLightModeTheme ? 'primary' : 'textSecondary'}
            >
              The Tax Planning & Accounting Firm
            </Typography>
          </Box>
        </Box>
      </Link>

      <div className={classes.flexGrow} />
      <FormControlLabel
        className={classes.darkmode}
        control={<Switch color="primary" checked={isLightModeTheme} />}
        label={
          <Typography variant="body1" color="textSecondary">
            Light
          </Typography>
        }
        labelPlacement="end"
        onChange={() => toggleMode()}
      />
      <Hidden smDown>
        <List disablePadding className={classes.navigationContainer}>
          {nav.map(item => {
            return (
              <ListItem aria-describedby={item.title} key={item.title}>
                <Typography
                  variant="subtitle1"
                  component={Link}
                  color="primary"
                  to={item.link}
                  className={clsx(classes.listItemText, 'menu-item')}
                >
                  {item.title}
                </Typography>
              </ListItem>
            );
          })}
        </List>
      </Hidden>
      <Hidden mdUp>
        <IconButton
          className={classes.iconButton}
          onClick={onSidebarOpen}
          aria-label="Menu"
        >
          <MenuIcon />
        </IconButton>
      </Hidden>
    </Toolbar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  //pages: PropTypes.object.isRequired,
};

export default Topbar;
