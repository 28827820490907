import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Link } from '@material-ui/core';
import { Link as GatsbyLink } from 'gatsby';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 0),
    },
    background: theme.palette.background.footer,
  },
  footerContainer: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  navLink: {
    color: 'rgba(255,255,255,.8)',
  },
  logoTitle: {
    color: 'rgba(255,255,255,.8)',
    [theme.breakpoints.up('xs')]: {
      flexFlow: 1,
      fontWeight: '400',
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
      flexFlow: 1,
      fontWeight: '700',
      display: 'block',
    },
  },
}));

const Footer = props => {
  const { className, nav, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.footerContainer}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={2}>
            <Grid container spacing={1} direction="column">
              {nav.map(item => {
                return (
                  <Grid item key={item.title}>
                    <Typography
                      component={GatsbyLink}
                      to={item.link}
                      className={classes.navLink}
                    >
                      {item.title}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={5}
            className={classes.navLink}
            spacing={1}
          >
            <Grid item xs={12} container direction="column" justify="flex-end">
              <Grid item>
                <Typography variant="h6" paragraph>
                  Ottawa Office
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  component={Link}
                  href="mailto:info@helenge.com"
                  className={classes.navLink}
                >
                  Email: info@helenge.com
                </Typography>
                <Typography variant="subtitle2">
                  Tel: 613-228-2847, 613-228-1796
                </Typography>
                <Typography variant="subtitle2">
                  356 Woodroffe Ave, Suite 206, Ottawa, Ontario K2A 3V6
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={5}
            className={classes.navLink}
            spacing={1}
          >
            <Grid item xs={12} container direction="column" justify="flex-end">
              <Grid item>
                <Typography variant="h6" paragraph>
                  Toronto Office
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  component={Link}
                  href="mailto:info@helenge.com"
                  className={classes.navLink}
                >
                  Email: info@helenge.com
                </Typography>
                <Typography variant="subtitle2" className={classes.navLink}>
                  Tel: 905-597-5844, 647-986-0906
                </Typography>
                <Typography variant="subtitle2">
                  45B West Wilmot St, Suite 202, Richmond Hill, ON L4B 2P3
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
